import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

import '../../global.css'
import favicon from '../../favicon.png'
import Nav from '../../navigation.js'
import Footer from '../../footer.js'

import carsBg from '../../carsBg.jpg'

var splendoBlue = '#2F76CA'

const Container = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  .sectionHeader {
    font-size: 1.5em;
    padding: 30px 0;
  }
`

const Hero = styled.header`
  text-align: left;
  position: relative;
  height: 50%;
  background-image: url(${carsBg});
  background-size: cover;
  background-position: center;
  @media (max-width: 800px) {
    text-align: center;
  }
  .wrapper {
    position: absolute;
    top: 50%;
    width: 100%;
    max-width: 600px;
    padding-left: 10%;
    transform: translateY(-50%);
    @media (max-width: 800px) {
      padding: 0;
    }
    h1 {
      font-size: 3em;
      @media (max-width: 800px) {
        font-size: 2em;
      }
    }
    span {
      font-family: nunitoReg, sans-serif;
    }
  }
`
const Details = styled.div`
  width: 80%;
  margin: 0 auto;
  padding: 100px 0;
  @media (max-width: 800px) {
    text-align: center;
  }
  h1 {
    padding-bottom: 30px;
  }

  p {
    font-family: nunitoReg, sans-serif;
    padding: 0 0 40px;
    width: 90%;
    max-width: 800px;
    @media (max-width: 800px) {
      font-size: 1em;
      text-align: center;
      width: 100%;
    }
  }

  a {
    display: inline-block;
    padding: 20px 40px;
    color: white;
    border-radius: 12px;
    background: ${splendoBlue};
    text-transform: uppercase;
  }
`
const Wrapper = styled.div`
  position: relative;
  height: 100%;
`

export default () => (
  <Container>
    <Wrapper>
      <Helmet>
        <title>Splendo</title>
        <meta charSet="utf-8" />
        <meta name="description" content="Splendo" />
        <link rel="icon" type="image/png" href={favicon} sizes="50x50" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      </Helmet>

      <Nav />

      <Hero>
        <div className="wrapper">
          <h1>AutoFill: Networked, Connected Cars</h1>
        </div>
      </Hero>

      <Details>
        <h1 className="sectionHeader">About AutoFill</h1>
        <p>
          AutoFill's sensor and video streaming technology tracks vast amounts of data and images of your vehicle and environment. In the event an
          accident occurs, our system automatically and securely sends data directly to your insurer.
        </p>
        <p>
          Liability can be a tricky thing when it comes to accidents. For peace of mind, use AutoFill's integrated optics to record video proof of
          fault in any incident.
        </p>

        <p>
          In the event of an accident, our integrated sensors instantly pick up over 10,000 data points and wirelessly sends this data to your
          preffered insurer. Incident data is sent wirelessly from our sensors to the AutoFill application. The information is compiled for easy
          sending to the insurer of your choice. Review a complete breakdown of your incident data -- add additonal photos and comments before sending
          to your logged insurance company.
        </p>

        <a href="https://www.autofill.eu" target="_blank" rel="noopener noreferrer">
          View Website
        </a>
      </Details>

      <Footer />
    </Wrapper>
  </Container>
)
